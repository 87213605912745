export const objectivesReferences = <const> [
  '6C10',
  '6C11',
  '6C12',
  '6C13',
  '6C14',
  '6C20',
  '6C21',
  '6C22',
  '6C30',
  '6C31',
  '6C32',
  '6C33',
  '6G10',
  '6G11',
  '6G12',
  '6G13',
  '6G14',
  '6G15',
  '6G20',
  '6G21',
  '6G22',
  '6G23',
  '6G24',
  '6G25',
  '6G30',
  '6G31',
  '6G32',
  '6G40',
  '6G41',
  '6G42',
  '6G50',
  '6G51',
  '6G52',
  '6G53',
  '6G54',
  '6G60',
  '6G61',
  '6G62',
  '6G70',
  '6G71',
  '6G72',
  '6M10',
  '6M11',
  '6M12',
  '6M13',
  '6M14',
  '6M15',
  '6M20',
  '6M21',
  '6M22',
  '6M30',
  '6M31',
  '6N10',
  '6N11',
  '6N20',
  '6N21',
  '6N22',
  '6N30',
  '6N31',
  '6N32',
  '6P10',
  '6P11',
  '6P12',
  '6P13',
  '6P14',
  '6P15',
  '6P16',
  '6P17',
  '6P18',
  '6S10',
  '6S11',
  '5G10',
  '5G11',
  '5G12',
  '5G20',
  '5G21',
  '5G22',
  '5G30',
  '5G31',
  '5G32',
  '5G40',
  '5G42',
  '5M10',
  '5M20',
  '5M21',
  '5N10',
  '5N11',
  '5N20',
  '5N21',
  '5N22',
  '5N23',
  '5N24',
  '5N30',
  '5N31',
  '5N32',
  '5N33',
  '5N34',
  '5N35',
  '5N40',
  '5N41',
  '5N42',
  '5N43',
  '5N50',
  '5N51',
  '5N52',
  '5N53',
  '5N54',
  '5N55',
  '5P10',
  '5P11',
  '5S10',
  '5S11',
  '5S12',
  '5S13',
  '5S20',
  '5S21',
  '5S30',
  '5S31',
  '5X1',
  '5X2',
  '4G10',
  '4G11',
  '4G12',
  '4G20',
  '4G21',
  '4G22',
  '4G23',
  '4G30',
  '4G31',
  '4G32',
  '4G40',
  '4G50',
  '4G51',
  '4G52',
  '4G53',
  '4N10',
  '4N20',
  '4N21',
  '4N22',
  '4N30',
  '4N31',
  '4N32',
  '4N33',
  '4N34',
  '4N35',
  '4N36',
  '4N40',
  '4N41',
  '4N42',
  '4N50',
  '4N51',
  '4N52',
  '4N53',
  '4N54',
  '4N55',
  '4N56',
  '4N57',
  '4P10',
  '4P11',
  '4P12',
  '4P14',
  '4S10',
  '4S11',
  '4S12',
  '4S13',
  '4S14',
  '4S20',
  '4S21',
  '3G10',
  '3G11',
  '3G12',
  '3G13',
  '3G14',
  '3G15',
  '3G20',
  '3G21',
  '3G30',
  '3G31',
  '3G32',
  '3G33',
  '3N10',
  '3N11',
  '3N20',
  '3N21',
  '3N22',
  '3N23',
  '3N24',
  '3P10',
  '3S11',
  '3S12',
  '3S20',
  '3S30',
  '3S31',
  '3S32',
  '3S33',
  '3S40',
  '3S41',
  '3S42',
  '3S43',
  '3S44',
  '3S50',
  '3S51',
  '3S52',
  '3S53',
  '3S54'
]
