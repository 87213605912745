<a
  href="https://coopmaths.fr/www/blog/2024-12-08-can2025"
  target="_blank"
  class="flex flex-col items-center mx-auto max-w-lg p-3 rounded-lg shadow
    bg-coopmaths-canvas-dark border border-gray-200 hover:bg-gray-200
    dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
>
  <h5 class="mb-2 text-2xl font-bold tracking-tight text-coopmaths-struct dark:text-white">
    Course aux nombres spéciale nouvelle année ! 
  </h5>
  <div class="flex space-x-2">
    <img
      src="/alea/assets/images/canSpeciale2025.png"
      alt="Logo CAN Speciale 2025"
      class="max-w-80 mb-4 flex"
    />
  </div>
  <p class="text-center font-normal text-gray-700 dark:text-gray-400">
    Pour cette fin d'année 2024, la team Coopmaths, en collaboration avec la DRANE de Normandie, vous propose une Course Aux Nombres spéciale nouvelle année 2025.
    Plus d'informations sur notre blog.
  </p>
</a>
